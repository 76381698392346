@tailwind base;
@tailwind components;
@tailwind utilities;
h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
a {
  @apply text-blue-600 underline;
}

html {
  font-family: "Poppins", sans-serif;
}
